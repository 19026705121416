import {isJSONObject, isJSONValue, storage} from '@backstage-components/base';
import type {GuestAuthChangeEvent} from '@backstage-components/base';
import {useCallback, useRef, useSyncExternalStore} from 'react';
import {useDomainName} from './data/useDomainName';

/** Type helper to extract the detail information from a `CustomEvent` */
type CustomEventDetail<T> = T extends CustomEvent<infer Detail>
  ? Detail
  : never;

/** Detail of the GuestAuthChangeEvent */
type AuthChangeDetail = CustomEventDetail<GuestAuthChangeEvent>;

/** Attendee or Guest information out of the `GuestAuthChangeEvent` */
type Attendee = AuthChangeDetail['attendee'];

/**
 * Check if the given `input` can be used as an `Attendee` record.
 */
function isAttendee(input: unknown): input is Attendee {
  return (
    isJSONValue(input) &&
    isJSONObject(input) &&
    'id' in input &&
    typeof input.id === 'string' &&
    'email' in input &&
    (typeof input.email === 'string' || input.email === null) &&
    'chatTokens' in input &&
    Array.isArray(input.chatTokens) &&
    'tags' in input &&
    Array.isArray(input.tags)
  );
}

/**
 * Attempt to read attendee information from `storage`
 */
function readAttendee(domainName: string | undefined): Attendee | null {
  const serialized = storage.getItem(`${domainName}/attendee`);
  try {
    if (typeof serialized === 'string') {
      const deserialized: unknown = JSON.parse(serialized);
      if (isAttendee(deserialized)) {
        return deserialized;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch {
    // stored data could not be deserialized
    return null;
  }
}

/**
 * Listen for changes to the currently authenticated guest and trigger a
 * re-render when the guest information changes.
 */
export function useAuthenticatedGuest(): Attendee | null {
  const location = useDomainName();
  const domainName = useRef(location?.domainName);
  const attendeeRef = useRef(readAttendee(domainName.current));
  const getSnapshot: Reader = useCallback(() => attendeeRef.current, []);
  const subscriber: Subscriber = useCallback((onChange) => {
    const listener = (e: GuestAuthChangeEvent): void => {
      const existing = attendeeRef.current;
      const next = e.detail.attendee;
      if (
        (existing === null && next !== null) ||
        (existing !== null && next === null) ||
        (existing !== null && next !== null && existing.id !== next.id)
      ) {
        attendeeRef.current = next;
        const serialized = JSON.stringify(next);
        storage.setItem(`${domainName.current}/attendee`, serialized);
        onChange();
      }
    };
    document.body.addEventListener('GuestAuth:change', listener);
    return () => {
      document.body.removeEventListener('GuestAuth:change', listener);
    };
  }, []);
  const value = useSyncExternalStore(subscriber, getSnapshot);
  return value;
}

/** Type alias for subscriber that notifies of changes to authenticated guest */
type Subscriber = Parameters<typeof useSyncExternalStore<Attendee | null>>[0];

/** Type alias for reader that gets snapshot of current authenticated guest */
type Reader = Parameters<typeof useSyncExternalStore<Attendee | null>>[1];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findCoreVariableObjects = findCoreVariableObjects;
exports.safeParse = safeParse;
function isCoreVariable(o) {
    return (typeof o === 'object' &&
        o !== null &&
        !Array.isArray(o) &&
        'path' in o &&
        'ref' in o &&
        typeof o.path === 'string' &&
        typeof o.ref === 'string');
}
/**
 * A content-managed input will submit a "stringified" object representing
 * a reference to a "site variable" to the form values.
 *
 * This function uses `JSON.stringify` to walk through submitted form values,
 * to look for and parse the site variable references, and returns
 * data that can be stored in a `core`'s `variables` column.
 */
function findCoreVariableObjects(formValues) {
    const foundObjs = [];
    if (formValues) {
        JSON.stringify(formValues, 
        // pass in a replacer function, to run on each value
        (_, value) => {
            // parse the string value
            const parsedValue = safeParse(value);
            // check if the parsed value is a `CoreVariable` object
            if (isCoreVariable(parsedValue)) {
                // push parsed value to `foundObjs`
                foundObjs.push(parsedValue);
            }
            // continue stringify call
            return value;
        });
    }
    return foundObjs;
}
/**
 * calls JSON.parse in a try/catch so we can ignore values that throw errors
 */
function safeParse(value) {
    try {
        return JSON.parse(value);
    }
    catch {
        // ignore errors
        return null;
    }
}

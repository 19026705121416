"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const InviteStatus = typebox_1.Type.Union([
    typebox_1.Type.Literal('active'),
    typebox_1.Type.Literal('used'),
    typebox_1.Type.Literal('revoked'),
]);
exports.InviteModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    email: typebox_1.Type.String({ format: 'email' }),
    organizationId: typebox_1.Type.String({ format: 'uuid' }),
    status: InviteStatus,
    ...timestamp_json_1.TimestampsJson.properties,
});

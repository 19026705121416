"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configureTypeboxFormats = configureTypeboxFormats;
const json_schema_format_helpers_all_1 = require("@backstage/utils/json-schema-format-helpers-all");
const typebox_1 = require("@sinclair/typebox");
const system_1 = require("@sinclair/typebox/system");
/**
 * Configures string formats with the typebox `TypeSystem` based on the formats
 * available in `@backstage/utils/json-schema-format-helpers-all`
 */
function configureTypeboxFormats() {
    const formats = Object.keys(json_schema_format_helpers_all_1.formatChecks);
    // If the formats are all configured already finish immediately
    if (formats.every((f) => typebox_1.FormatRegistry.Has(f))) {
        return;
    }
    for (const [id, fn] of Object.entries(json_schema_format_helpers_all_1.formatChecks)) {
        system_1.TypeSystem.Format(id, fn);
    }
}

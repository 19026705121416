"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailBatchDynamoMapper = exports.EmailBatchJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const dynamodb_mapper_1 = require("../dynamodb-mapper");
const email_batch_model_1 = require("./email-batch-model");
function modelFromJson(item, mode) {
    const isEmailBatch = (0, json_schema_1.createValidator)(email_batch_model_1.EmailBatch);
    if (isEmailBatch(item)) {
        return {
            sendgridBatchId: item.sendgridBatchId,
            batchId: item.batchId,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isEmailBatch.errors ?? [];
    }
}
exports.EmailBatchJsonMapper = {
    fromJSON: modelFromJson,
    toJSON: (record) => ({
        updatedAt: record.updatedAt.toISOString(),
        createdAt: record.createdAt.toISOString(),
        batchId: record.batchId,
        sendgridBatchId: record.sendgridBatchId,
    }),
};
exports.EmailBatchDynamoMapper = (0, dynamodb_mapper_1.createDynamoMapper)('EmailBatch', exports.EmailBatchJsonMapper);

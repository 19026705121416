"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDynamoMapper = createDynamoMapper;
const util_dynamodb_1 = require("@aws-sdk/util-dynamodb");
const errors_1 = require("../errors");
const json_1 = require("../json");
/**
 * Create an object capable of mapping to and from a DynamoDB representation of
 * a type based on a JSON representation.
 * @param kind to use as the name of the record
 * @param jsonMapper used to serialize a JSON representation before (during
 * `toItem`) and after (during `fromItem`) serializing to a DynamoDB
 * representation.
 * @returns a `DynamoDbMapper` using the given `jsonMapper` as the
 * underpinnings.
 */
function createDynamoMapper(kind, jsonMapper) {
    const fromItem = (item, mode) => {
        const record = (0, util_dynamodb_1.unmarshall)(item);
        if ((0, json_1.isJSONValue)(record) && (0, json_1.isJSONObject)(record)) {
            const result = jsonMapper.fromJSON(record);
            if (mode === 'data-only' && Array.isArray(result)) {
                return undefined;
            }
            else if (Array.isArray(result)) {
                throw new errors_1.ValidationError(result);
            }
            else if (typeof result === 'undefined') {
                throw new Error(`${kind} can not be extracted from item`);
            }
            else {
                return result;
            }
        }
        else if (mode === 'data-only') {
            return undefined;
        }
        else if (mode === 'errors') {
            throw new Error(`${kind} can not be extracted from item`);
        }
    };
    const mapToItem = (jsonRecord) => {
        // Convert to a record of `Type` first in order to eliminate extra properties
        const record = jsonMapper.fromJSON(jsonRecord);
        if (Array.isArray(record)) {
            // Throwing here because this is an exceptional situation, the input here
            // was a valid JSON representation according to types so it should also
            // pass schema validation.
            throw new errors_1.ValidationError(record);
        }
        else {
            return (0, util_dynamodb_1.marshall)(jsonMapper.toJSON(record), { removeUndefinedValues: true });
        }
    };
    const toItem = (record) => {
        const json = jsonMapper.toJSON(record);
        return (0, util_dynamodb_1.marshall)(json, { removeUndefinedValues: true });
    };
    return {
        fromItem,
        mapToItem,
        toItem,
    };
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailMessageEvent = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
/**
 * Identifies a Sendgrid template for sending dynamic email.
 */
const SendgridTemplateId = typebox_1.Type.String({
    format: 'regex',
    pattern: '^d-[a-z0-9]{32}',
});
/**
 * Define a general purpose Event Bridge message detail schema for triggering
 * emails.
 */
const EmailEventDetail = typebox_1.Type.Object({
    templateId: typebox_1.Type.String({
        title: 'Email template',
        description: 'Id of the email template to be sent',
    }),
    from: model_1.Recipient,
    to: model_1.Recipient,
    subject: typebox_1.Type.String({
        description: 'Subject line of email',
        maxLength: 300,
    }),
    triggerId: typebox_1.Type.String({
        description: 'Trigger ID of email event',
        format: 'uuid',
    }),
});
/**
 * Define a specialization of the `EmailEventDetail` for the "send a batch email
 * to guests" use case.
 */
const GuestBatchEventDetail = typebox_1.Type.Composite([
    EmailEventDetail,
    typebox_1.Type.Object({
        kind: typebox_1.Type.Literal('guest-batch'),
        guest_name: typebox_1.Type.String({
            title: 'Guest Name',
            description: 'The name by which the guest should be addressed',
            examples: ['First Last', 'ScreenName'],
        }),
        accessCode: typebox_1.Type.String({ description: 'Access Code of guest' }),
        templateId: SendgridTemplateId,
    }),
]);
/**
 * Define a specialization of the `EmailEventDetail` for the "resend access
 * code" use case.
 */
const ResendAccessCodeEventDetail = typebox_1.Type.Composite([
    EmailEventDetail,
    typebox_1.Type.Object({
        kind: typebox_1.Type.Literal('resend-access-code'),
        accessCode: typebox_1.Type.String({
            description: 'Access Code of guest',
        }),
        templateId: SendgridTemplateId,
    }),
]);
const SendInviteEventDetail = typebox_1.Type.Composite([
    EmailEventDetail,
    typebox_1.Type.Object({
        kind: typebox_1.Type.Literal('admin-invite'),
        inviteLink: typebox_1.Type.String({
            description: 'Link to create account with invite token',
            format: 'uri',
        }),
        templateId: SendgridTemplateId,
    }),
]);
const EmailMessage = typebox_1.Type.Union([
    GuestBatchEventDetail,
    ResendAccessCodeEventDetail,
    SendInviteEventDetail,
]);
exports.EmailMessageEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /**
         * An id representing a batch of emails to be sent at the same time.
         * Including a `batchId` in the message includes the `messages` in the
         * identified batch. A batch may be canceled or paused via email provider
         * tools.
         */
        batchId: typebox_1.Type.Optional(typebox_1.Type.String({ minLength: 32 })),
        messages: typebox_1.Type.Array(EmailMessage),
    }),
    'detail-type': typebox_1.Type.Literal('EmailMessage'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeGuestExternalId = deserializeGuestExternalId;
exports.extractGuestExternalId = extractGuestExternalId;
exports.extractGuestExternalAttendeeId = extractGuestExternalAttendeeId;
const guest_external_service_type_1 = require("./guest-external-service-type");
/**
 * Extract the identifying details about a `GuestExternal` record from an input
 * string. If the details can not be extracted returns `undefined`.
 */
function deserializeGuestExternalId(input) {
    const parts = input.split(':');
    const [serviceType, externalId] = parts;
    if (parts.length > 2 ||
        typeof serviceType === 'undefined' ||
        typeof externalId === 'undefined') {
        return undefined;
    }
    else if (guest_external_service_type_1.GuestExternalServiceType.const === serviceType) {
        return { externalId, serviceType };
    }
    else {
        return undefined;
    }
}
/**
 * Generate an `id` value for `GuestExternal` based on the details of the
 * record.
 */
function extractGuestExternalId(source) {
    return `${source.serviceType}:${source.externalId}`;
}
/**
 * Generate an `Attendee.id` from a `GuestExternal` record. `GuestExternal`
 * contains sufficient detail to create a synthetic `Attendee` record and in
 * that case the generated `id` for the `Attendee` should be stable.
 */
function extractGuestExternalAttendeeId(source) {
    const identifier = extractGuestExternalId(source);
    return `${identifier}:attendee`;
}

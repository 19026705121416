"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coreTypes = void 0;
exports.isCoreType = isCoreType;
/*
 * If this set ever increases beyond default|group, be sure to update the logic
 * for `MutationCreatePage` since copying currently expects only these two types.
 */
exports.coreTypes = {
    default: 'default',
    group: 'group',
};
/**
 * Checks if the given `value` is a valid `CoreType`
 */
function isCoreType(value) {
    return typeof value === 'string' && value in exports.coreTypes;
}

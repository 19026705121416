"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTrigger = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
const recipient_model_1 = require("./recipient-model");
exports.EmailTrigger = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    batchId: typebox_1.Type.String({ format: 'uuid' }),
    from: recipient_model_1.Recipient,
    recipients: typebox_1.Type.Array(recipient_model_1.Recipient),
    subject: typebox_1.Type.String(),
    templateId: typebox_1.Type.String(),
    templateData: (0, model_helpers_1.JsonObject)(),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'EmailTrigger' });

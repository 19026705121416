"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortItems = void 0;
exports.sortCollectionItems = sortCollectionItems;
const json_1 = require("./json");
/**
 * Returns a new array sorted by the `sortField` provided. Invalid `sortField`
 * values will return a copy of the original array, reversing if requested.
 * **NOTE**: Absent values are sorted to the end of the array.
 * **NOTE**: Only `createdAt`, `updatedAt` are currently the only fully reliable
 * date sort fields because they're known to be date strings. We cannot itentify
 * other fields as dates, but ISO strings are sortable as strings but a timezone
 * offset can throw off the sort for dates within 24 hours of each other.
 */
function sortCollectionItems(itemsOriginal, sortField, isReversed = false) {
    // Defensively shallow copy the array so we don't mutate the original.
    const items = itemsOriginal.slice();
    if (!sortField && isReversed) {
        return items.reverse();
    }
    if (!sortField || itemsOriginal.length < 2) {
        // Return the copy even here
        return items;
    }
    return (0, exports.sortItems)(items, sortField, isReversed);
}
/**
 * Sort an array of items that look like collection items by a string or number
 * `sortField`
 * @private exported for testing
 */
const sortItems = (items, sortField, isReversed) => items
    .map((item) => ({
    // Schwartzian transform to simplify checks.
    original: item,
    fieldValue: extractField(item, sortField),
}))
    .sort(({ fieldValue: a }, { fieldValue: b }) => {
    // Don't shuffle unnecessarily
    if (a === null && b === null)
        return 0;
    // ...but work nulls to the end of the array
    if (a === null)
        return 1;
    if (b === null)
        return -1;
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b) * (isReversed ? -1 : 1);
    }
    else if (typeof a === 'number' && typeof b === 'number') {
        return isReversed ? b - a : a - b;
    }
    return 0;
})
    .map((x) => x.original);
exports.sortItems = sortItems;
const extractField = (item, field) => {
    if (field === 'updatedAt' || field === 'createdAt') {
        return item[field] ? new Date(item[field]).getTime() : null;
    }
    else if (field === 'name' || field === 'slug') {
        return item[field];
    }
    else if ((0, json_1.isJSONObject)(item.value) && field in item.value) {
        const fieldValue = item.value[field];
        if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
            return fieldValue;
        }
    }
    return null;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractMessageSchema = extractMessageSchema;
const typebox_1 = require("@sinclair/typebox");
const event_bus_message_1 = require("./event-bus-message");
/**
 * Find a specific `EventBusMessage` member schema schema based on the
 * `DetailType` provided.
 */
function extractMessageSchema(type) {
    const schema = event_bus_message_1.EventBusMessage.anyOf.find((s) => s[typebox_1.Kind] === 'Object' && s.properties['detail-type'].const === type);
    if (typeof schema === 'undefined') {
        throw new Error(`EventBusMessage not found for ${type}`);
    }
    else {
        // @ts-expect-error convincing TypeScript `schema` is of type
        // `ExtractMessageSchema<DetailType>` inside this function causes "Type
        // instantiation is excessively deep and possibly infinite.ts(2589)" errors
        // so telling TS this is correct and moving on
        return schema;
    }
}

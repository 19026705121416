"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTriggerJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const email_trigger_model_1 = require("./email-trigger-model");
function modelFromJson(item, mode) {
    const isEmailTriggerItem = (0, json_schema_1.createValidator)(email_trigger_model_1.EmailTrigger);
    if (isEmailTriggerItem(item)) {
        return {
            batchId: item.batchId,
            id: item.id,
            from: item.from,
            recipients: item.recipients,
            subject: item.subject,
            templateData: item.templateData,
            templateId: item.templateId,
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isEmailTriggerItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `EmailTrigger`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `EmailTrigger`.
 */
function modelToJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        batchId: record.batchId,
        id: record.id,
        from: record.from,
        recipients: record.recipients,
        subject: record.subject,
        templateData: record.templateData,
        templateId: record.templateId,
    };
}
exports.EmailTriggerJsonMapper = {
    fromJSON: modelFromJson,
    toJSON: modelToJson,
};

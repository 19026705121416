"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailBatch = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
exports.EmailBatch = typebox_1.Type.Object({
    batchId: typebox_1.Type.String({ format: 'uuid' }),
    /**
     * Id from scheduled send API
     * @see https://www.twilio.com/docs/sendgrid/api-reference/cancel-scheduled-sends/create-a-batch-id
     */
    sendgridBatchId: typebox_1.Type.Optional(typebox_1.Type.String()),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'EmailBatch' });

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeErrorResponseSchema = makeErrorResponseSchema;
const typebox_1 = require("@sinclair/typebox");
/**
 * Creates an Object schema with an error description and the given `code`
 * schema.
 */
function makeErrorResponseSchema(code) {
    return typebox_1.Type.Object({
        code,
        error: typebox_1.Type.String({
            description: 'Describes the error',
        }),
    });
}
